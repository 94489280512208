import React from 'react';
import Select from 'react-select';
import { Link } from "gatsby"
import Header from '../Header';
import {StaticImage} from 'gatsby-plugin-image';
import "./styles.scss"

interface HomepageProps {
  language: string,
  country: string
};

const Homepage:React.FC<HomepageProps> = ({
  language,
  country
}) => {
  


    return (
      <>
        <Header language={language} country={country} urlParts={["/"]} />
        <div className="home-wrapper">
          <p>english flag</p>
          <p>japanese flag</p>
          
        </div>
      </>
    )
};

export default Homepage;
