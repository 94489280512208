import * as React from "react"
import Homepage from "../components/Homepage";
import '../templates/styles.scss';

// markup
const IndexPage = () => {

  const country = "en";
  const language = "en";

  return (
    <Homepage country={country} language={language} />
  )
}

export default IndexPage
